import {
  GridLayout,
  TabStrip,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import { useEffect, useState } from "react";
import { PostRequestCall } from "../../apicall/PostRequest";
import {
  FETCH_COMPANIES,
  FETCH_COMPANIES_ADDRESSES,
  FETCH_COMPANIES_TRADES,
} from "../../constant/Apipath";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CompanyProfileSection from "./CompanyProfileSection";
import "./../companies.css";
import CompanyProjectList from "./CompanyProjectList";
import CompanyProducts from "./CompanyProducts";

function CompanyDetailPage() {
  const { companyId } = useParams();
  const { loginData } = useSelector((state) => state.main);

  const [showLoader, setShowLoader] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);

  const [companyData, setCompanyData] = useState({
    companyDetails: null,
    companyAddress: [],
    tradeList: [],
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const [companyDetails, companyAddress, tradeList] = await Promise.all([
        fetchCompaniesDetails().catch((e) => null), // handle errors individually
        getCompanyAddressList().catch((e) => []),
        getCompanyTradeList().catch((e) => []),
      ]);
      setCompanyData({
        companyDetails,
        companyAddress,
        tradeList,
      });
    } catch (error) {
      console.log("Error while getting company details :: ", error);
    } finally {
      setShowLoader(false);
    }
  };

  const fetchCompaniesDetails = async () => {
    try {
      let obj = { SearchList: [{ Id: companyId }] };
      const res = await PostRequestCall(FETCH_COMPANIES, obj, loginData?.token);

      let resData = res?.data?.data?.[0] ?? null;
      return resData;
    } catch (error) {
      console.log("Error while getting compnay details :: ", error);
    }
  };

  const getCompanyAddressList = async () => {
    try {
      const obj = {
        SearchList: [{ companyId: companyId }],
      };
      const res = await PostRequestCall(
        FETCH_COMPANIES_ADDRESSES,
        obj,
        loginData?.token
      );
      let resData = res?.data?.data ?? [];
      return resData;
    } catch (error) {
      console.log("Error while getting company address list :: ", error);
    }
  };

  const getCompanyTradeList = async () => {
    try {
      let obj = {
        SearchList: [{ CompanyId: companyId }],
      };
      const res = await PostRequestCall(
        FETCH_COMPANIES_TRADES,
        obj,
        loginData?.token
      );
      let resData = res?.data?.data ?? [];
      return resData ?? [];
    } catch (error) {
      console.log("Error while getting trade list :: ", error);
    }
  };

  const handleTabSelect = (e) => {
    setSelectedTab(e.selected);
  };

  const onDataSaveSuccessfully = async (
    updateCompanyDetails,
    updateTradeDetails,
    updateAddressDetails
  ) => {
    try {
      setShowLoader(true);
      const apiCalls = [];
      const previousCompanyData = { ...companyData };

      if (updateCompanyDetails) {
        apiCalls.push(fetchCompaniesDetails().catch((e) => null));
      } else {
        apiCalls.push(Promise.resolve(previousCompanyData.companyDetails));
      }

      if (updateAddressDetails) {
        apiCalls.push(getCompanyAddressList().catch((e) => []));
      } else {
        apiCalls.push(Promise.resolve(previousCompanyData.companyAddress));
      }

      if (updateTradeDetails) {
        apiCalls.push(getCompanyTradeList().catch((e) => []));
      } else {
        apiCalls.push(Promise.resolve(previousCompanyData.tradeList));
      }

      const [companyDetails, companyAddress, tradeList] = await Promise.all(
        apiCalls
      );

      setCompanyData({
        companyDetails: updateCompanyDetails
          ? companyDetails
          : previousCompanyData.companyDetails,
        companyAddress: updateAddressDetails
          ? companyAddress
          : previousCompanyData.companyAddress,
        tradeList: updateTradeDetails
          ? tradeList
          : previousCompanyData.tradeList,
      });
    } catch (error) {
      console.log("Error while getting company details :: ", error);
    } finally {
      setShowLoader(false);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <div>
      <div
        className="report-section"
        style={{ height: "100%", overflow: "hidden" }}>
        <GridLayout
          rows={[{ height: "100%" }]}
          cols={[{ width: "35%" }, { width: "65%" }]}
          gap={{ rows: 1, cols: 0 }}
          style={{
            height: "100%",
            overflow: "hidden",
            borderRadius: "10px",
          }}>
          <div style={{ position: "relative", width: "100%" }}>
            <CompanyProfileSection
              companyDetails={companyData?.companyDetails}
              companyAddress={companyData?.companyAddress}
              tradeList={companyData?.tradeList ?? []}
              callUpdatedCompanyData={onDataSaveSuccessfully}
            />
          </div>

          <div
            style={{
              height: "90vh",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              backgroundColor: "#fff",
              paddingLeft: '10px',
              borderRadius: '6px'
            }}
            className="company_tab_strip_conatiner"
          >
            <TabStrip
              selected={selectedTab}
              onSelect={handleTabSelect}
              style={{
                flexGrow: 1,
                overflow: "hidden",
                paddingTop: "5px",
                height: "100%",
              }}>
              <TabStripTab title="Projects" style={{ backgroundColor: 'red', width: '100%', height: '100%' }} className="rcx">
                <CompanyProjectList />
              </TabStripTab>
              <TabStripTab title="Products">
                <CompanyProducts />
              </TabStripTab>
            </TabStrip>
          </div>
        </GridLayout>
      </div>
    </div>
  );
}

export default CompanyDetailPage;



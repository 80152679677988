import * as Icons from 'react-bootstrap-icons';
import React from "react";

// To check is it the current icon is valid or not 
const isValidIconName = (props) => {
    if (typeof props?.iconName !== "undefined" && props?.iconName !== "") {
        if (Object.keys(Icons).includes(props?.iconName)) {
            return React.createElement(Icons[props?.iconName], { size: props?.size, color: props?.color, style: { cursor: "pointer", backgroundColor: props?.backgroundColor ?? "" }, className: props?.className, onClick: props?.onClick, backgroundColor: props?.backgroundColor ?? "" });
        } else {
            return React.createElement(Icons["HouseDoorFill"], { size: props?.size, color: props.color, style: { cursor: "pointer", backgroundColor: props?.backgroundColor ?? "" } });
        }
    }
};

export const IconComponent = (props) => {
    return isValidIconName(props);
}

import React, { useEffect, useRef, useState } from "react";
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import KendoButton from "../../common/KendoButton";
import {
  FETCH_PRODUCTS,
  FETCH_MANUFACTURER,
  SAVE_PRODUCTS,
  SAVE_COMPANY_PRODUCTS,
  SAVE_MANUFACTURER,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IconComponent } from "../../common/Icon";
import { Loader } from "@progress/kendo-react-indicators"; // Assuming this is the loader component you're using

const AddNewProduct = ({
  showForm,
  onClose,
  fetchCompanieProduct,
  getObjectForApiCall,
}) => {
  const { companyId } = useParams();
  const [isFormValid, setIsFormValid] = useState(false);
  const { loginData } = useSelector((state) => state.main);
  const [productOptions, setProductOptions] = useState([]);
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [selectProduct, setSelectProduct] = useState(null);
  const [isNewProduct, setIsNewProduct] = useState(false);
  const [newProductName, setNewProductName] = useState("");
  const [productOpen, setProductOpen] = useState(false);
  const [newManufacturerName, setNewManufacturerName] = useState("");
  const [manufacturer, setManufacturer] = useState(null);
  const [manufacturerOpen, setManufacturerOpen] = useState(false);
  const [type, setType] = useState("Material");
  const [searchLoader, setSearchLoader] = useState(false);
  const [searchmanufacturerLoader, setSearchmanufacturerLoader] =
    useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);


  const DebounceTimeout = useRef(null);

  const getProductlist = async (searchTerm) => {
    try {
      let productObj = {
        NrOfRecPerPage: 20,
        FullSearch: searchTerm,
        UserId: loginData.userId,
        SearchList: [{ name: selectProduct }],
        SortList: [{ FieldName: "Id", Direction: "ASC" }],
        IncludeRecordNr: true,
      };
      const productRes = await PostRequestCall(
        FETCH_PRODUCTS,
        productObj,
        loginData?.token
      );
      let productData = productRes?.data?.data?.map((record) => ({
        value: record?.id,
        label: record?.name,
        manufacturer: record?.manufacturerName,
        manufacturerid: record?.manufacturerId,
        type: record?.productType,
      }));
      setProductOptions(productData ?? []);
    } catch (error) {
      console.log("Error while getting product list :: ", error);
    } finally {
      setSearchLoader(false);
    }
  };
  useEffect(() => {
    if (showForm) {
      getProductlist(""); // Initial fetch when form opens
    }
  }, [showForm]);

  useEffect(() => {
    setProductOptions([]);
  }, []);

  // const getManufacturerList = async (searchTerm) => {
  //   try {
  //     setSearchLoader(true);

  //     const manufacturerRes = await PostRequestCall(
  //       FETCH_MANUFACTURER,
  //       {
  //         PageNr: 1,
  //         NrOfRecPerPage: 20,
  //         FullSearch: searchTerm,
  //         UserId: loginData.userId,
  //         IncludeRecordNr: "true",
  //         TypeOfObjectReturned: "",
  //         FetchAllowedRecordsOnly: false,
  // SearchList: [
  //   {
  //     manufacturer: true,
  //   },
  // ],
  //         SortList: [
  //           {
  //             FieldName: "Id",
  //             Direction: "DESC",
  //           },
  //         ],
  //       },
  //       loginData?.token
  //     );

  //     let manufacturerData = manufacturerRes?.data?.data?.map((record) => ({
  // value: record?.id,
  // label: record?.name,
  //     }));

  //     setManufacturerOptions(manufacturerData ?? []);
  //   } catch (error) {
  //     console.log("Error while getting manufacturer list :: ", error);
  //   } finally {
  //     setSearchLoader(false);
  //   }
  // };


  useEffect(() => {
    if (showForm) {
      getManufacturerList(""); // Initial fetch when form opens
    }
  }, [showForm]);

  useEffect(() => {
    validateForm();
  }, [newProductName, newManufacturerName, selectProduct, type]);

  const validateForm = () => {
    if (isNewProduct) {
      setIsFormValid(newProductName && newManufacturerName && type);
    } else {
      setIsFormValid(selectProduct !== null);
    }
  };


  const getManufacturerList = async (searchTerm) => {
    try {
      let manufactureObj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: searchTerm,
        UserId: loginData.userId,
        FetchAllowedRecordsOnly: false,
        SearchList: [
          {
            manufacturer: true,
          },
        ],
        SortList: [{ FieldName: "Id", Direction: "DESC" }],
        IncludeRecordNr: true,
      };
      const manufacturerRes = await PostRequestCall(
        FETCH_PRODUCTS,
        manufactureObj,
        loginData?.token
      );
      let manufacturerData = manufacturerRes?.data?.data?.map((record) => ({
        value: record?.id,
        label: record?.name,
      }));
      setManufacturerOptions(manufacturerData ?? []);
    } catch (error) {
      console.log("Error while getting product list :: ", error);
    } finally {
      setSearchmanufacturerLoader(false);
    }
  };

  const saveProduct = async () => {
    try {
      setSubmitLoader(true);
      let manufacturerId = manufacturer?.value;
      let productId;

      if (isNewProduct) {
        if (newProductName && newManufacturerName) {
          const manufacturerPayload = {
            userId: loginData.userId,
            ReturnRecordId: true,
            ReturnRecordError: true,
            SaveList: [
              {
                Id: 0,
                Name: newManufacturerName,
                phone: "",
                ModifiedBy: loginData.userId,
                RecordStatusId: 1,
                Manufacturer: true,
                CountryCode: "",
                WebSite: "",
              },
            ],
          };
          const manufacturerResponse = await PostRequestCall(
            SAVE_MANUFACTURER,
            manufacturerPayload,
            loginData?.token
          );
          manufacturerId =
            manufacturerResponse?.data?.data?.[0]?.objData?.id || 0;
        }

        const productPayload = {
          UserId: loginData.userId,
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: [
            {
              Id: 0,
              Name: newProductName,
              RecordStatusId: 1,
              ModifiedBy: loginData.userId,
              ManufacturerId: manufacturerId,
              productType: type || "Material",
            },
          ],
        };
        const productResponse = await PostRequestCall(
          SAVE_PRODUCTS,
          productPayload,
          loginData?.token
        );
        productId = productResponse?.data?.data?.[0]?.objData?.id;
      } else {
        productId = selectProduct?.value;
        manufacturerId = selectProduct?.manufacturerid || manufacturerId;
      }

      if (productId) {
        const companyPayload = {
          UserId: loginData.userId,
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: [
            {
              Id: 0,
              ProductId: productId,
              CompanyId: companyId,
              ModifiedBy: loginData.userId,
              RecordStatusId: 1,
            },
          ],
        };
        await PostRequestCall(
          SAVE_COMPANY_PRODUCTS,
          companyPayload,
          loginData?.token
        );

        setSubmitLoader(false);
        setSelectProduct(null);
        setIsNewProduct(false);
        setManufacturer(null);
        setNewProductName("");
        setNewManufacturerName("");
        setType(null);
        setProductOptions([]);
        setManufacturerOptions([]);
        onClose();
        fetchCompanieProduct(getObjectForApiCall());
      }
    } catch (error) {
      setSubmitLoader(false);
      console.log("Error while saving product ::", error);
    }
  };

  const onCompanyChange = (event) => {
    const value = event?.target?.value;
    const selected = productOptions.find(
      (product) => product.label === event?.target?.value
    );
    setSelectProduct(selected);
    setIsNewProduct(!selected);

    if (value?.length > 2) {
      setSearchLoader(true);
      if (DebounceTimeout.current) {
        clearTimeout(DebounceTimeout.current);
      }

      DebounceTimeout.current = setTimeout(() => {
        getProductlist(value);
      }, 1000);
    }



    if (!selected) {
      setNewProductName(value);
    }
    validateForm();
  };

  const onManufacurerChange = (event) => {
    const value = event?.target?.value;

    const selected = manufacturerOptions.find(
      (s) => s.label === event.target.value
    );
    setNewManufacturerName(value);
    setManufacturer(selected || null);

    if (value?.length > 2) {
      setSearchmanufacturerLoader(true);
      if (DebounceTimeout.current) {
        clearTimeout(DebounceTimeout.current);
      }
      DebounceTimeout.current = setTimeout(() => {
        getManufacturerList(value);
      }, 1000);
    } else {
      setManufacturerOptions([]);
    }

  };


  const handleClose = () => {
    setSelectProduct(null);
    setIsNewProduct(false);
    setManufacturer(null);
    setNewProductName("");
    setNewManufacturerName("");
    setType("Material");
    setProductOptions([]);
    setManufacturerOptions([]);
    onClose();
  };
  if (!showForm) return null;

  return (
    <div style={overlayStyles.overlay}>
      <div style={overlayStyles.overlayContent}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconComponent size={25} iconName="X" onClick={handleClose} />
        </div>
        <h3>Add New Product</h3>
        <div style={overlayStyles.formGroup}>
          <p>
            <b>Product:</b>
          </p>

          <AutoComplete
            data={productOptions}
            value={selectProduct?.label ?? newProductName}
            className="field-ip"
            onChange={onCompanyChange}
            textField="label"
            opened={productOpen}
            placeholder="Select Product"
            itemRender={(li, itemProps) => {
              const trade = itemProps.dataItem;
              return (
                <div
                  className="user-item"
                  onClick={() => {
                    setSelectProduct(trade);
                    setProductOpen(false);
                  }}>
                  <span>{trade.label}</span>
                </div>
              );
            }}
            onFocus={() => setProductOpen(true)}
            onBlur={() => setProductOpen(false)}
          />
          {selectProduct?.label?.trim() === "" && (
            <div
              style={{
                position: "absolute",
                top: "35%",
                right: "10px",
                cursor: "pointer",
                zIndex: 1,
              }}
              onClick={() => setProductOpen((prev) => !prev)}>
              <IconComponent
                size={20}
                iconName={productOpen ? "ChevronUp" : "ChevronDown"}
                color="#B5B5B5"
              />
            </div>
          )}
          {searchLoader === true && selectProduct?.label?.trim() !== "" && (
            <div
              style={{
                position: "absolute",
                top: "18%",
                right: "28px",
                zIndex: 1,
                backgroundColor: "#fff",
              }}>
              <Loader size="small" style={{ color: "#083DB8" }} />
            </div>
          )}
        </div>

        {selectProduct ? (
          <div>
            <p>
              <b>Manufacturer:</b> {selectProduct?.manufacturer || "N/A"}
            </p>
            <p>
              <b>Type:</b> {selectProduct?.type || "N/A"}
            </p>
          </div>
        ) : (


          newProductName !== "" &&
          (
            <div>
              <p>Adding a new Product to the database:</p>
              <div style={overlayStyles.formGroup}>
                <p>
                  <b>Manufacturer:</b>
                </p>
                {/* <AutoComplete
              data={manufacturerOptions}
              value={newManufacturerName}
              onChange={onManufacurerChange}
              textField="label"
              placeholder="Select Manufacturer.."
            /> */}

                <div style={{ position: 'relative' }}>
                  <AutoComplete
                    data={manufacturerOptions}
                    value={manufacturer?.label ?? newManufacturerName}
                    // value={newManufacturerName}
                    className="field-ip"
                    onChange={onManufacurerChange}
                    textField="label"
                    opened={manufacturerOpen}
                    placeholder="Select manufacturer..."
                    itemRender={(li, itemProps) => {
                      const trade = itemProps.dataItem;
                      return (
                        <div
                          className="user-item"
                          onClick={() => {
                            setManufacturer(trade);
                            setManufacturerOpen(false);
                          }}>
                          <span>{trade.label}</span>
                        </div>
                      );
                    }}
                    onFocus={() => setManufacturerOpen(true)}
                    onBlur={() => setManufacturerOpen(false)}
                  />
                  {manufacturer?.label?.trim() === "" && (
                    <div
                      style={{
                        position: "absolute",
                        top: "35%",
                        right: "10px",
                        cursor: "pointer",
                        zIndex: 1,
                      }}
                      onClick={() => setManufacturerOpen((prev) => !prev)}>
                      <IconComponent
                        size={20}
                        iconName={manufacturerOpen ? "ChevronUp" : "ChevronDown"}
                        color="#B5B5B5"
                      />
                    </div>
                  )}
                  {searchmanufacturerLoader === true &&
                    manufacturer?.label?.trim() !== "" && (
                      <div
                        style={{
                          position: "absolute",
                          top: "10%",
                          right: "8px",
                          zIndex: 9999,
                          backgroundColor: "#fff",
                        }}>
                        <Loader size="small" style={{ color: "#083DB8" }} />
                      </div>
                    )}
                </div>
              </div>
              <div style={overlayStyles.formGroup}>
                <p>
                  <b>Type:</b>
                </p>
                <DropDownList
                  data={["Tool", "Material"]}
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  placeholder="Select type..."
                />
              </div>
            </div>
          )
        )}

        <div style={overlayStyles.actions}>
          {
            submitLoader ?
              (
                <div className="action-btn-loader" style={{ marginLeft: 'auto', width: '30px', height: '20px' }}>
                  <Loader type={"pulsing"} themeColor="white" size='small' />
                </div>
              )
              :
              (
                <KendoButton
                  iconClass=""
                  size={16}
                  className="action-btn"
                  buttonName="Save"
                  // disabled={!isFormValid}
                  onClick={saveProduct}
                />
              )
          }
        </div>
      </div>
    </div>
  );
};

export default AddNewProduct;

const overlayStyles = {
  overlay: {
    position: "fixed",
    top: "67px",
    right: 0,
    width: "400px",
    height: "100%",
    background: "white",
    boxShadow: "-2px 0 5px rgba(0,0,0,0.2)",
    transform: "translateX(0)",
    transition: "transform 0.3s ease-in-out",
    borderRadius: "10px 0 0 10px",
  },
  overlayContent: {
    padding: "20px",
  },
  closeBtn: {
    float: "right",
  },
  formGroup: {
    marginTop: "15px",
  },
  actions: {
    marginTop: "20px",
    textAlign: "right",
  },
};

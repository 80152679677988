import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardTitle, CardBody, CardActions, CardSubtitle, CardImage } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { Loader } from '@progress/kendo-react-indicators';
import { PostRequestCall } from '../../apicall/PostRequest';
import { FECTH_USER_PRODUCTS } from '../../constant/Apipath';
import { useSelector } from 'react-redux';
import { IconComponent } from '../../common/Icon';
import ProductImg from "../../assets/images/placeholder_img.jpg";


const UserProductReportSection = () => {

    const { loginData } = useSelector((state) => state.main);


    const { id } = useParams();
    const [showLoader, setShowLoader] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, [id]);

    const getData = async () => {
        try {
            var data = JSON.stringify({
                SearchList: [{ userId: id }],
                IncludeRecordNr: true,
                FetchAllowedRecordsOnly: false,
                DoNotShowInSystemFields: false,
            });
            const res = await PostRequestCall(
                FECTH_USER_PRODUCTS,
                data,
                loginData?.token
            );
            let resData = res?.data?.data ?? [];
            setData(resData)
        } catch (error) {
            console.log("Error while getting user product list :: ", error);
        } finally {
            setShowLoader(false);
        }
    };

    const renderStar = (starCount, noOfCountRatings) => {
        if (typeof starCount !== 'number' || isNaN(starCount) || starCount < 0 || starCount > 5) {
            starCount = 0;
        }

        const fullStars = Math.floor(starCount);
        const hasHalfStar = starCount % 1 >= 0.5 ? 1 : 0;
        const emptyStars = 5 - fullStars - hasHalfStar;

        return (
            <div style={{ display: 'flex', gap: '10px', margin: '0px', padding: '0px', height: '20px', justifyContent: 'start', alignItems: 'center', paddingBottom: '5px' }}>
                <div style={{ display: 'flex', gap: '2px' }}>
                    {Array(fullStars).fill().map((_, index) => (
                        <IconComponent
                            key={`full-${index}`}
                            iconName={"StarFill"}
                            size={15}
                            color="yellow"
                        />
                    ))}

                    {hasHalfStar === 1 && (
                        <IconComponent
                            iconName={"StarHalf"}
                            size={15}
                            color="yellow"
                        />
                    )}

                    {Array(emptyStars).fill().map((_, index) => (
                        <IconComponent
                            key={`empty-${index}`}
                            iconName={"Star"}
                            size={15}
                            color="yellow"
                        />
                    ))}
                </div>
                <p style={{ margin: '0px', color: 'gray' }}>{noOfCountRatings}</p>
            </div>
        );
    };

    return (
        <>
            <div style={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
                {!showLoader ? (
                    <>
                        {
                            data?.length > 0 ? (
                                <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                    <div className={'k-card-deck'} style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        // gap: '30px',
                                        // justifyContent: 'center',
                                        margin: '0 auto',
                                    }}>
                                        {data?.map((item, index) => (
                                            <div key={index} style={{ flex: '1 1 calc(33.33% - 16px)', maxWidth: '180px' }} className='up_custom_card_container'>
                                                <Card className="up-custom-card" style={{ padding: '0px' }}>
                                                    <CardBody>
                                                        <div className="up-user-img-container">
                                                            <CardImage
                                                                className="up-user-img"
                                                                src={item?.pictureUrl?.trim() !== "" ? item?.pictureUrl : ProductImg}
                                                                onError={(e) => {
                                                                    e.target.src = ProductImg
                                                                }}
                                                                alt={item?.productName ?? ""}
                                                            />
                                                        </div>

                                                        <div className='up-card-description'>
                                                            <div>
                                                                {
                                                                    item?.manufacturerName &&
                                                                    (
                                                                        <CardSubtitle className="up-card-subtitle">{item?.manufacturerName ?? "asdas"}</CardSubtitle>
                                                                    )
                                                                }
                                                                <CardTitle className="up-card-title">{item?.productName ?? ""}</CardTitle>
                                                            </div>

                                                            <div>
                                                                {renderStar(item?.averageRating ?? 0, item?.noOfCountRatings ?? 0)}
                                                            </div>
                                                        </div>

                                                    </CardBody>
                                                </Card>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div className="up-no-products">
                                    This user has not added any products to their profile.
                                </div>
                            )
                        }

                    </>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '400px' }}>
                        <Loader
                            type="converging-spinner"
                            className="kendo-spinner"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                minHeight: "500px",
                                alignItems: "center",
                            }}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default UserProductReportSection;

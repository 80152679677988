import React, { useEffect, useState } from 'react'
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Label } from '@progress/kendo-react-labels';
import { useSelector } from 'react-redux';
import { PostRequestCall } from '../apicall/PostRequest';
import { FETCH_RECORD_STATUSES } from '../constant/Apipath';

const RecordStatusInput = (props) => {

    const [recordStatusOptions, setRecordStatusOptions] = useState([]);
    const { loginData } = useSelector((state) => state.main);

    useEffect(() => {
        getRecordStatuses();
    }, [])

    const getRecordStatuses = async () => {
        try {
            let recordStatusesObj = {
                PageNr: 1,
                NrOfRecPerPage: 100,
                FullSearch: "",
                UserId: "1",
                SortList: [
                    {
                        FieldName: "Id",
                        Direction: "ASC",
                    },
                ],
                IncludeRecordNr: true,
            };
            const recordStatusesRes = await PostRequestCall(
                FETCH_RECORD_STATUSES,
                recordStatusesObj,
                loginData?.token
            );
            let recordStatusesData = recordStatusesRes?.data?.data?.map((record) => ({
                id: record?.id,
                text: record?.name,
            }));
            setRecordStatusOptions(recordStatusesData ?? []);
        } catch (error) {
            console.log("Error while getting record statuses data :: ", error);
        }
    };



    return (
        <>
            {!props?.hideLabel && <Label className="form-heading">Record Status:</Label>}
            <div>
                <DropDownList
                    style={{ width: props?.noExtraWidth ? "" : "370px" }}
                    onChange={(e) => {
                        let recordStatus = e.value?.text
                        let recordStatusId = e.value?.id
                        props?.onChange({ recordStatus, recordStatusId })
                    }}
                    className="dropdown-list"
                    value={{
                        id: props?.recordStatusId,
                        text: props?.recordStatus,
                    }}
                    data={recordStatusOptions}
                    textField="text"
                    dataItemKey="id"
                />
            </div>
        </>
    )
}

export default RecordStatusInput